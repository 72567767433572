<template>
  <ValidationObserver ref="observer" tag="Form" v-slot="{ pristine, invalid }">
    <SelectQlikApplications v-model="qlikApps" :disabled="loading" />
    <Button
      :disabled="pristine || invalid"
      :loading="loading"
      @click="confirmModal"
    >
      {{ $t("APPLY") }}
    </Button>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate-full";
import revokeQlikApplicationsFromTenantQuery from "./queries/revokeQlikApplicationsFromTenant.gql";
import grantQlikApplicationsToTenantQuery from "./queries/grantQlikApplicationsToTenant.gql";
import SelectQlikApplications from "@/Organizations/OnboardOrganization/components/SelectQlikApplications/SelectQlikApplications.vue";

export default {
  components: { ValidationObserver, SelectQlikApplications },
  inject: ["organization"],
  data: ({ organization: { qlikApps } }) => ({
    loading: false,
    qlikApps: [...qlikApps]
  }),
  methods: {
    confirmModal() {
      this.$Modal.confirm({
        title: this.$t("CONFIRMATION"),
        content: `${this.$t(
          "YOU_ARE_ABOUT_TO_GIVE_ACCESS_TO_QLIK_APPS_TO_ALL_USERS_OF_ORGANIZATION",
          {
            qlikApps: `<code>${this.qlikApps
              .map(({ name }) => name)
              .join(`</code>, <code>`)}</code>`,
            organization: `<code>${this.organization.displayName}</code>`
          }
        )}<br /><br />
        ${this.$t("ARE_YOU_SURE")}?`,
        okText: this.$t("CONFIRM"),
        cancelText: this.$t("CANCEL"),
        onOk: this.saveQlikApplications
      });
    },
    async saveQlikApplications() {
      this.loading = true;
      const { id } = this.organization;
      const qlikAppIds = this.qlikApps.map(({ id }) => id);

      await this.$apollo
        .mutate({
          mutation: revokeQlikApplicationsFromTenantQuery,
          variables: {
            id,
            qlikAppIds: this.organization.qlikApps.map(({ id }) => id)
          }
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        );
      const grant = await this.$apollo
        .mutate({
          mutation: grantQlikApplicationsToTenantQuery,
          variables: { id, qlikAppIds }
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        );

      if (grant) {
        const { qlikApps } = grant.data.grantQlikAppsToTenant;
        this.organization.qlikApps = qlikApps;
        this.$refs.observer.reset();
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
