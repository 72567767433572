<template>
  <Error :error="error">
    <div class="flex">
      <label class="ivu-form-item-label">{{ $t("QLIK_APPLICATIONS") }}</label>
      <div class="flex-grow ivu-form-item-label">
        <span class="text-gray-400">
          {{
            $t(
              "DETERMINE_WHAT_DATA_MEMBERS_OF_THIS_ORGANIZATION_WILL_HAVE_ACCESS_TO"
            )
          }}
        </span>
      </div>
    </div>
    <ValidationProvider
      slim
      :name="`&quot;${$t('QLIK_APPLICATIONS')}&quot;`"
      rules="required"
      immediate
      v-slot="{ pristine, errors }"
      ref="provider"
    >
      <FormItem :error="pristine ? '' : errors[0]">
        <Select
          v-model="selectedQlikApps"
          :loading="$apollo.loading"
          :disabled="disabled"
          @on-change="handleChange"
          filterable
          multiple
        >
          <Option v-for="{ id, name } in qlikApps" :value="id" :key="id">
            {{ name }}
          </Option>
        </Select>
      </FormItem>
    </ValidationProvider>
  </Error>
</template>

<script>
import { ValidationProvider } from "vee-validate-full";
import qlikAppsQuery from "./queries/qlikApps.gql";
import Error from "@/components/Error/Error.vue";

export default {
  components: { ValidationProvider, Error },
  props: {
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({ error: null, pristine: true, qlikApps: [] }),
  apollo: {
    qlikApps: {
      query: qlikAppsQuery,
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(networkError || graphQLErrors);
      }
    }
  },
  computed: {
    selectedQlikApps: {
      get: ({ value }) => value.map(({ id }) => id),
      set(qlikAppIds) {
        const qlikApps = this.qlikApps.filter(({ id }) =>
          qlikAppIds.includes(id)
        );
        this.$emit("input", qlikApps);
      }
    }
  },
  methods: {
    handleChange() {
      // Workaround: reset ValidationProvider after setting the initial field value (first change)
      if (this.pristine) {
        this.$refs.provider.reset();
        this.pristine = false;
      }
    }
  }
};
</script>

<style scoped></style>
